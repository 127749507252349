<template>
  <div class="about">
    <!-- 公司简介 -->
    <div
      style="
        width: 100%;
        padding-top: 70px;
        padding-bottom: 79px;
        background-color: #f5f5f5;
      "
    >
      <div class="about-company">
        <div class="about-company-title">
          <img src="~@a/images/about-images/gsjj.png" alt="" />
        </div>
        <div class="about-company-container">
          <div class="about-company-container-title">
            {{ profileData.title }}
          </div>
          <div
            class="about-company-container-desc"
            v-html="profileData.content"
          ></div>
        </div>
        <div class="about-company-container-concept">
          <div class="concept-title" v-if="ideaData.length">
            {{ ideaData[0].categoryName }}
          </div>
        </div>
        <div class="about-company-concept-list">
          <div
            class="company-concept-list"
            v-for="item in ideaData"
            :key="item.id"
          >
            <img :src="item.imagePath" alt="" />
            <div class="company-concept-list-txt">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 业务范围 -->
    <div
      style="
        width: 100%;
        background-color: #fff;
        padding-top: 70px;
        padding-bottom: 100px;
      "
    >
      <div class="about-scope">
        <div class="about-scope-title">
          <img src="~@a/images/about-images/ywfw.png" alt="" />
        </div>
        <div class="about-scope-content">
          <div class="about-scope-img" v-for="item in scopeData" :key="item.id">
            <img :src="item.path" alt="" />
            <div class="about-scope-img-txt">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 行业资质 -->
    <div
      style="
        width: 100%;
        background-color: #fff;
        padding-top: 70px;
        padding-bottom: 77px;
      "
      v-if="false"
    >
      <div class="about-qualification">
        <div class="about-qualification-title">
          <img src="~@a/images/about-images/hyzz.png" alt="" />
        </div>
        <div class="about-qualification-shuffling">
          <carousel-3d
            :autoplay="true"
            width="424"
            height="600"
            :controlsVisible="true"
            :onSlideChange="onSlideChange"
          >
            <slide v-for="(item, index) in data" :key="index" :index="index">
              <img :src="item.path" alt="" style="height: 100%;" />
            </slide>
          </carousel-3d>
          <div class="shuffling-title">
            <div class="shffling-title-txt">
              {{ shfflingTitle }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 政府合作 -->
    <div
      style="
        width: 100%;
        padding-top: 70px;
        padding-bottom: 80px;
        background-color: #f5f5f5;
      "
      v-if="false"
    >
      <div class="about-government">
        <div class="about-government-title">
          <img src="~@a/images/about-images/zfhz.png" alt="" />
        </div>
        <div class="about-government-content">
          <div
            class="about-government-content-img"
            v-for="(item, index) in 5"
            :key="index"
          >
            <img src="~@a/images/about-images/yuan-4.png" alt="" />
            <div
              class="government-content-img-title animate__animated animate__fadeInUp"
            >
              <div class="government-content-img-title-txt">
                中国银行行长
                原江苏省副省长王江、连云港市委书记项雪龙、省政府副秘书长
                王思源、原江苏省文化厅厅长 徐耀新 莅临公司视察调研
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 员工风采 -->
    <div
      style="
        width: 100%;
        padding-top: 70px;
        padding-bottom: 78px;
        background-color: #fff;
      "
      v-if="false"
    >
      <div class="about-employees">
        <div class="about-employees-title">
          <img src="~@a/images/about-images/ygfc.png" alt="" />
        </div>
        <div class="about-employees-content">
          <div
            class="about-employees-content-img"
            v-for="(item, index) in 5"
            :key="index"
          >
            <img src="~@a/images/about-images/yuan-5.png" alt="" />
            <div
              class="employees-content-img-title animate__animated animate__fadeInUp"
            >
              <div class="employees-content-img-title-txt">
                中国银行行长
                原江苏省副省长王江、连云港市委书记项雪龙、省政府副秘书长
                王思源、原江苏省文化厅厅长 徐耀新 莅临公司视察调研
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div
      style="
        width: 100%;
        padding-top: 70px;
        padding-bottom: 80px;
        background-color: #f6f6f6;
        margin-bottom: 20px;
      "
    >
      <div class="about-us">
        <div class="about-us-title">
          <img src="~@a/images/about-images/lxwm.png" alt="" />
        </div>
        <div class="about-us-content">
          <div class="about-us-content-left">
            <div class="about-us-content-left-box">
              <div class="about-us-content-left-box-l1">
                <img src="~@a/images/about-images/company-title.png" alt="" />
              </div>
              <div class="about-us-content-left-box-l2">
                <img src="~@a/images/about-images/company-phone.png" alt="" />
                <div class="about-us-l2-right">
                  <div class="about-us-l2-phone">
                    电话 ：
                  </div>
                  <div class="about-us-l2-num">
                    <!-- {{ inforData.val4 }} -->
                    13357868100
                  </div>
                </div>
              </div>
              <div class="about-us-content-left-box-l2">
                <img src="~@a/images/about-images/company-email.png" alt="" />
                <div class="about-us-l2-right">
                  <div class="about-us-l2-phone">
                    邮箱 ：
                  </div>
                  <div class="about-us-l2-num">
                    {{ inforData.val8 }}
                  </div>
                </div>
              </div>
              <div class="about-us-content-left-box-l3" @click="mapAdress='NJ'">
                <img src="~@a/images/about-images/company-located.png" alt="" />
                <div class="about-us-l2-right">
                  <div class="about-us-l2-address">
                    南京市秦淮区光华东街6号17-202室
                  </div>
                </div>
              </div>
              <div class="about-us-content-left-box-l4" @click="mapAdress='LYG'">
                <img src="~@a/images/about-images/company-located.png" alt="" />
                <div class="about-us-l2-right">
                  <div class="about-us-l2-address">
                    {{ address }}
                  </div>
                  <!-- <div class="about-us-l2-addressinfo">
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="about-us-content-right">
            <baidu-component
              :addressObj="mapAdress"
              class="bm-view"
              ak="UmeYALQE9awE98LBNtNivQfr8hEoNP7W"
            ></baidu-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
import BaiduComponent from '@/components/baiduMap'
// import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import 'animate.css'
import { GetSpecial, GetBanners, GetCustom } from '@/api/aboutApi.js'

export default {
  components: {
    Carousel3d,
    Slide,
    BaiduComponent,
  },
  data() {
    return {
      shfflingTitle: '', //3d轮播图标题,
      baiduMap: 'false',
      flag: 1,
      mapAdress:'NJ',
      // data: [
      //   {
      //     id: 1,
      //     title: '江苏大塔电子采购平台PC端软件【简称：电子采购平台PC端软件】',
      //   },
      //   {
      //     id: 2,
      //     title: '哈哈1',
      //   },
      //   {
      //     id: 3,
      //     title: '哈哈2',
      //   },
      //   {
      //     id: 4,
      //     title: '哈哈3',
      //   },
      //   {
      //     id: 5,
      //     title: '哈哈4',
      //   },
      // ],
      //公司简介
      profileData: {},
      //优质的理念
      ideaData: [],
      //资质
      customData: [],
      //公司信息
      inforData: {},
      address: '',
      scopeData: [], //业务范围
    }
  },
  updated() {
    // about-company-container-desc
  },

  created() {
    this.init()
  },
  methods: {
    async init() {
      let special = await GetSpecial('profile')
      //优质的理念
      let idea = await GetSpecial('service_idea')
      let infor = await GetCustom('contact_us')
      let custom = await GetBanners()
      this.profileData = special.result
      this.ideaData = idea.result
      //业务范围
      this.scopeData = custom.result
      console.log(this.scopeData)
      //轮播图
      // this.data = custom.result
      // this.shfflingTitle = this.data[0].title
      this.inforData = infor.result
      this.address = this.inforData.val2
    },

    //轮播图切换事件
    // onSlideChange(idx) {
    //   // console.log(idx)
    //   this.data.forEach((item, index) => {
    //     if (index === idx) {
    //       this.shfflingTitle = item.title
    //     }
    //   })
    // },
  },
}
</script>
<style lang="less" scoped>
.bm-view {
  width: 100%;
  height: 300px;
}
.about {
  //公司简介
  .about-company {
    width: 1200px;
    margin: 0 auto;
    .about-company-title {
      width: 1200px;
      height: 99px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .about-company-container {
      width: 1200px;
      margin-top: 33px;
      .about-company-container-title {
        font-size: 26px;
        color: #333;
        line-height: 36px;
        font-weight: bold;
      }
      .about-company-container-desc {
        font-size: 18px;
        color: #666;
        line-height: 36px;
        > p {
          font-size: 18px;
          color: #666;
          line-height: 36px;
        }
      }
    }
    .about-company-container-concept {
      width: 1200px;
      line-height: 36px;
      margin-top: 36px;
      border-bottom: 1px solid #ec9000;
      .concept-title {
        font-size: 26px;
        font-weight: bold;
        color: #333;
        line-height: 36px;
      }
    }
    .about-company-concept-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
      .company-concept-list {
        display: flex;
        margin-right: 94px;
        > img {
          width: 120px;
          height: 60px;
        }
        &:nth-of-type(1) {
          margin-bottom: 39px;
        }

        &:nth-of-type(2) {
          margin-bottom: 39px;
        }
        .company-concept-list-txt {
          font-size: 26px;
          width: 320px;
          height: 35px;
          font-family: MicrosoftYaHei;
          line-height: 60px;
          margin-left: -40px;
        }
      }
    }
  }

  //行业范围
  .about-scope {
    width: 1200px;
    margin: 0 auto;
    .about-scope-title {
      width: 1200px;
      height: 100px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .about-scope-content {
      width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 40px;
      .about-scope-img {
        width: 380px;
        height: 310px;
        position: relative;
        > img {
          width: 100%;
          height: 100%;
        }
        &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3) {
          margin-bottom: 30px;

        }
        .about-scope-img-txt {
          position: absolute;
          bottom: 20px;
          width: 100%;
          text-align: center;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
        }
      }
    }
  }

  //公司资质
  // .about-qualification {
  //   position: relative;
  //   width: 1200px;
  //   margin: 0 auto;
  //   .about-qualification-title {
  //     width: 1200px;
  //     height: 99px;
  //     > img {
  //       width: 100%;
  //       height: 100%;
  //     }
  //   }
  //   .about-qualification-shuffling {
  //     width: 1200px;
  //     margin-top: 30px;
  //     .shuffling-title {
  //       margin-top: 10px;
  //       font-size: 26px;
  //       font-family: MicrosoftYaHei;
  //       line-height: 39px;
  //       color: #333333;
  //       text-align: center;
  //     }
  //   }
  // }
  //政府合作
  // .about-government {
  //   width: 1200px;
  //   margin: 0 auto;
  //   .about-government-title {
  //     width: 1200px;
  //     height: 99px;
  //     > img {
  //       width: 100%;
  //       height: 100%;
  //       object-fit: cover;
  //     }
  //   }
  //   .about-government-content {
  //     width: 1200px;
  //     height: 490px;
  //     margin-top: 39px;
  //     display: flex;
  //     flex-wrap: wrap;
  //     justify-content: space-between;
  //     .about-government-content-img {
  //       position: relative;
  //       width: 380px;
  //       height: 230px;
  //       cursor: pointer;
  //       overflow: hidden;
  //       > img {
  //         width: 100%;
  //         height: 100%;
  //       }
  //       &:hover {
  //         .government-content-img-title {
  //           display: block;
  //           transition: 1000ms;
  //           height: 84px;
  //         }
  //       }
  //       .government-content-img-title {
  //         position: absolute;
  //         left: 0;
  //         bottom: 0;
  //         width: 100%;
  //         height: 0;
  //         background: rgba(227, 85, 19, 0.6);

  //         display: none;

  //         .government-content-img-title-txt {
  //           margin: 15px auto 0 auto;
  //           width: 340px;
  //           height: 52px;
  //           font-size: 18px;
  //           color: #fff;
  //           font-family: MicrosoftYaHei;
  //           line-height: 52px;
  //           white-space: nowrap;
  //           overflow: hidden;
  //           text-overflow: ellipsis;
  //         }
  //       }
  //       &:nth-of-type(1) {
  //         width: 790px;
  //         height: 230px;
  //         > img {
  //           width: 100%;
  //           height: 100%;
  //           object-fit: cover;
  //         }
  //         .government-content-img-title-txt {
  //           margin: 15px auto 0 auto;
  //           width: 750px;
  //           height: 52px;
  //           font-size: 18px;
  //           color: #fff;
  //           font-family: MicrosoftYaHei;
  //           line-height: 27px;
  //           white-space: pre-wrap;
  //           overflow: hidden;
  //           text-overflow: ellipsis;
  //           display: -webkit-box;
  //           -webkit-line-clamp: 2;
  //           -webkit-box-orient: vertical;
  //         }
  //       }
  //     }
  //   }
  // }
  //员工风采
  // .about-employees {
  //   width: 1200px;
  //   margin: 0 auto;
  //   .about-employees-title {
  //     width: 1200px;
  //     height: 99px;
  //     > img {
  //       width: 100%;
  //       height: 100%;
  //       object-fit: cover;
  //     }
  //   }
  //   .about-employees-content {
  //     width: 1200px;
  //     height: 490px;
  //     margin-top: 39px;
  //     display: flex;
  //     flex-wrap: wrap;
  //     justify-content: space-between;
  //     .about-employees-content-img {
  //       position: relative;
  //       width: 380px;
  //       height: 230px;
  //       > img {
  //         width: 100%;
  //         height: 100%;
  //         object-fit: cover;
  //       }
  //       cursor: pointer;
  //       overflow: hidden;

  //       &:hover {
  //         .employees-content-img-title {
  //           display: block;
  //           transition: 1000ms;
  //           height: 84px;
  //         }
  //       }
  //       .employees-content-img-title {
  //         position: absolute;
  //         left: 0;
  //         bottom: 0;
  //         width: 100%;
  //         height: 0;
  //         background: rgba(227, 85, 19, 0.6);

  //         display: none;

  //         .employees-content-img-title-txt {
  //           margin: 15px auto 0 auto;
  //           width: 340px;
  //           height: 52px;
  //           font-size: 18px;
  //           color: #fff;
  //           font-family: MicrosoftYaHei;
  //           line-height: 52px;
  //           white-space: nowrap;
  //           overflow: hidden;
  //           text-overflow: ellipsis;
  //         }
  //       }

  //       &:nth-of-type(1) {
  //         width: 790px;
  //         height: 230px;
  //         > img {
  //           width: 100%;
  //           height: 100%;
  //         }
  //         .employees-content-img-title-txt {
  //           margin: 15px auto 0 auto;
  //           width: 750px;
  //           height: 52px;
  //           font-size: 18px;
  //           color: #fff;
  //           font-family: MicrosoftYaHei;
  //           line-height: 27px;
  //           white-space: pre-wrap;
  //           overflow: hidden;
  //           text-overflow: ellipsis;
  //           display: -webkit-box;
  //           -webkit-line-clamp: 2;
  //           -webkit-box-orient: vertical;
  //         }
  //       }
  //     }
  //   }
  // }
  //联系我们
  .about-us {
    width: 1200px;
    margin: 0 auto;
    .about-us-title {
      width: 1200px;
      height: 99px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .about-us-content {
      display: flex;
      margin-top: 39px;
      width: 1200px;
      height: 472px;
      .about-us-content-left {
        height: 472px;
        width: 450px;
        background: #fff;
        .about-us-content-left-box {
          margin-left: 45px;
          .about-us-content-left-box-l1 {
            margin-top: 33px;
            width: 360px;
            border-bottom: 1px solid gray;
            img {
              margin-bottom: 17px;
              width: 360px;
              height: 50px;
            }
          }
          .about-us-content-left-box-l2 {
            display: flex;
            margin-top: 20px;
            width: 360px;
            padding-bottom: 21px;
            border-bottom: 1px solid gray;
            .about-us-l2-right {
              margin-left: 20px;
              height: 45px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .about-us-l2-phone {
                font-size: 18px;
                color: #666;
              }
              .about-us-l2-num {
                font-size: 18px;
                color: #666;
              }
            }
            img {
              width: 50px;
              height: 50px;
            }
          }
          .about-us-content-left-box-l3 {
            cursor: pointer;
            display: flex;
            margin-top: 20px;
            width: 360px;
            padding-bottom: 21px;
            border-bottom: 1px solid gray;
            .about-us-l2-right {
              margin-left: 20px;
              height: 45px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .about-us-l2-address {
                // vertical-align: middle;
                line-height: 23px;
                font-size: 16px;
                color: #666;
              }
              // .about-us-l2-addressinfo {
              //   font-size: 16px;
              //   color: #666;
              // }
            }
            img {
              width: 50px;
              height: 50px;
            }
          }
          .about-us-content-left-box-l4 {
            cursor: pointer;
            display: flex;
            margin-top: 20px;
            width: 360px;
            padding-bottom: 21px;
            .about-us-l2-right {
              margin-left: 20px;
              height: 45px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .about-us-l2-address {
                // vertical-align: middle;
                line-height: 23px;
                font-size: 16px;
                color: #666;
              }
              // .about-us-l2-addressinfo {
              //   font-size: 16px;
              //   color: #666;
              // }
            }
            img {
              width: 50px;
              height: 50px;
            }
          }
        }
      }
      .about-us-content-right {
        height: 472px;
        width: 750px;
        // background: green;
      }
    }
  }
}
</style>
