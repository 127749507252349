<template>
<div>
  <baidu-map scroll-wheel-zoom class="map" ak="UmeYALQE9awE98LBNtNivQfr8hEoNP7W" :center="centerObj" :zoom="zoom" @ready="handler">
    <bm-marker
            :position="{lng: centerObj.lng, lat: centerObj.lat}"
            :zoom="18"
            :icon="{url: require('../../assets/images/about-images/building.png'), size: {width: 90, height: 64}}"
    ></bm-marker>
    <bm-navigation v-if="flag" anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
</baidu-map>
</div>
</template>
<script>
import {
  BaiduMap,
  BmView,
  BmLocalSearch,
  BmNavigation,
  BmMarker
} from 'vue-baidu-map'
export default {
    components: {
    BaiduMap,
    BmView,
    BmLocalSearch,
    BmMarker,
    BmNavigation
  },
  props: {
    addressObj: { type: String, default: 'NJ' },
  },
  data () {
    return {
      flag:false,
      center: {lng: 119.237422, lat: 34.610249},
      zoom: 3,
      resultList: [],

    }
  },
  computed: {
    centerObj () {
      if(this.addressObj=='LYG'){
        return {
          lng: 119.237422,
          lat: 34.610249
        }
      }else{
        return {
          lng: 118.829109,
          lat: 32.031069
        }
      }
      
    }
  },
  created(){
    setTimeout(()=>{
      this.flag = true
    },1000)

  },
  methods: {
    handler ({BMap, map}) {
      // console.log(BMap, map)
      this.center.lng = 119.237422
      this.center.lat = 34.610249
      this.zoom = 15
    },
    
  }
}
</script>
<style lang="less" scoped>
.map {
    height: 472px;
  }
</style>
